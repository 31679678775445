import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUp } from "@fortawesome/free-solid-svg-icons"
import Header from "./NewHeader"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Helmet } from "react-helmet"

import "./layout.css"

const Layout = ({
  children,
  location,
  links = [
    "Perspectives",
    "Book: Smarter Together",
    "Book: Value as a Service",
    "News",
    "Photo Gallery",
    "About Rob"
  ],
  homeLinks
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <Helmet>
        <script
          async
          src="https://platform.twitter.com/widgets.js"
          charset="utf-8"
        ></script>
      </Helmet>
      <Header
        links={links}
        siteTitle={data.site.siteMetadata.title}
        homeLinks={homeLinks}
        location={location}
      />
      <div>
        <a id="top" className="anchor" href="/">
          {null}
        </a>
        <main>{children}</main>
        <footer>
          <div className="container mx-auto">
            <div className="grid grid-cols-1">
              <div>
                <p
                  style={{
                    padding: "15px 0px",
                    lineHeight: "1rem"
                  }}
                >
                  Copyright © {new Date().getFullYear()}
                </p>
              </div>
            </div>
          </div>
          <AnchorLink to="#top" className="px-10 py-5 toTopButton">
            <FontAwesomeIcon icon={faArrowUp} size="2x" />
          </AnchorLink>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string,
  first: PropTypes.string,
  second: PropTypes.string,
  third: PropTypes.string,
  fourth: PropTypes.string,
  fifth: PropTypes.string
}
export default Layout
