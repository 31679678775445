import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { nanoid } from "nanoid"
import twitterIcon from "../images/Social_Twitter.svg"
import linkedinIcon from "../images/Social_LinkedIn.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links"

function Header(props) {
  const [isExpanded, toggleExpansion] = useState(false)
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false)
  const [isMobileLanguageMenuOpen, setIsMobileLanguageMenuOpen] = useState(
    false
  )
  const pageLinks = {
    link1: "/#news",
    link2: "/#smarterTogether",
    link3: "/#valueAsAService",
    link4: "/#perspectives",
    link5: "/gallery",
    link6: "/#aboutRob"
  }

  if (!props.homeLinks) {
    const path = props.location.pathname
    // pathnames for the anchorlinks
    pageLinks.link1 = `${path}#news`
    pageLinks.link2 = `${path}#smarterTogether`
    pageLinks.link3 = `${path}#valueAsAService`
    pageLinks.link4 = `${path}#perspectives`
    pageLinks.link5 = "/gallery"
    pageLinks.link6 = `${path}#aboutRob`
  }

  const fullLinks = []

  if (props.links.length !== Object.keys(pageLinks).length) {
    throw new Error(
      `The Header props.links.length must be ${
        Object.keys(pageLinks).length
      } but was instead ${props.links.length}`
    )
  }

  for (const key in pageLinks) {
    fullLinks.push({ to: pageLinks[key], name: null, id: nanoid() })
  }

  props.links.map((link, index) => {
    fullLinks[index].name = link
    return null
  })

  return (
    <div className="fixForHeader">
      <nav className="w-full bg-white shadow">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex justify-between headerHeight">
            <div className="flex w-full space-between">
              <div className="flex items-center flex-shrink-0">
                <Link
                  to="/"
                  className="block mr-4 font-semibold smooth lg:inline-block lg:mt-0 text-coupa-blue hover:text-coupa-blue-light"
                >
                  Rob Bernshteyn
                </Link>
              </div>
              <div className="hidden lg:ml-6 lg:flex">
                {fullLinks.map(link => {
                  return (
                    <AnchorLink
                      to={link.to}
                      key={link.id}
                      className="inline-flex items-center px-4 pt-1 mx-2 text-xs font-medium text-coupa-blue hover:text-coupa-blue-light md:px-1 leading-5 focus:outline-none transition duration-150 ease-in-out"
                    >
                      {link.name}
                    </AnchorLink>
                  )
                })}
                <div>
                  <div className="relative">
                    <div className="relative inline-flex items-center">
                      <button
                        type="button"
                        className="inline-flex items-center pr-12 text-xs font-medium text-coupa-blue hover:text-coupa-blue-light leading-5 focus:outline-none transition duration-150 ease-in-out"
                        style={{
                          width: "30px",
                          padding: "0",
                          marginRight: "5px"
                        }}
                        id="options-menu"
                        aria-haspopup="true"
                        aria-expanded="true"
                        onClick={() =>
                          setIsLanguageMenuOpen(!isLanguageMenuOpen)
                        }
                      >
                        <img
                          style={{ maxWidth: "30px" }}
                          className="block mt-4 social lg:inline-block text-coupa-blue hover:text-coupa-blue-light"
                          src="/images/globe.svg"
                          alt=""
                        />
                      </button>
                    </div>
                    <div
                      className={`${
                        isLanguageMenuOpen ? "block" : "hidden"
                      } absolute -mt-2 shadow-lg rounded-md -right-16`}
                    >
                      <div className="bg-white rounded-md shadow-xs">
                        <div
                          className="px-6"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="options-menu"
                        >
                          <Link
                            to="/"
                            className="block px-4 py-2 text-sm text-gray-700 leading-5 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                            role="menuitem"
                          >
                            English
                          </Link>
                          <Link
                            to="/fr/"
                            className="block px-4 py-2 text-sm text-gray-700 leading-5 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                            role="menuitem"
                          >
                            French
                          </Link>
                          <Link
                            to="/de/"
                            className="block px-4 py-2 text-sm text-gray-700 leading-5 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                            role="menuitem"
                          >
                            German
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  className="inline-flex items-center text-xs font-medium text-coupa-blue hover:text-coupa-blue-light leading-5 focus:outline-none transition duration-150 ease-in-out"
                  style={{ width: "30px" }}
                  href="https://twitter.com/rbernshteyn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    style={{ maxWidth: "30px" }}
                    className="block mt-6 social lg:inline-block text-coupa-blue hover:text-coupa-blue-light"
                    src={twitterIcon}
                    alt=""
                  />
                </a>
                <a
                  className="inline-flex items-center ml-1 text-xs font-medium text-coupa-blue hover:text-coupa-blue-light leading-5 focus:outline-none transition duration-150 ease-in-out"
                  style={{ width: "30px" }}
                  href="https://www.linkedin.com/in/rbernshteyn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    style={{ maxWidth: "30px" }}
                    className="block mt-6 social lg:inline-block text-coupa-blue hover:text-coupa-blue-light"
                    src={linkedinIcon}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div>
              <button
                type="button"
                className="inline-flex items-center pr-12 text-xs font-medium text-coupa-blue hover:text-coupa-blue-light leading-5 focus:outline-none transition duration-150 ease-in-out lg:hidden"
                style={{ width: "30px", padding: "0", marginRight: "5px" }}
                id="options-menu"
                aria-haspopup="true"
                aria-expanded="true"
                onClick={() =>
                  setIsMobileLanguageMenuOpen(!isMobileLanguageMenuOpen)
                }
              >
                <img
                  style={{ maxWidth: "30px" }}
                  className="block mt-4 social lg:inline-block text-coupa-blue hover:text-coupa-blue-light"
                  src="/images/globe.svg"
                  alt=""
                />
              </button>
              <div
                className={`${
                  isMobileLanguageMenuOpen ? "block" : "hidden"
                } absolute mr-5 -mt-2 shadow-lg rounded-md right-0`}
              >
                <div className="bg-white rounded-md shadow-xs">
                  <div
                    className="px-6"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <Link
                      to="/"
                      className="block px-4 py-2 text-sm text-gray-700 leading-5 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                      role="menuitem"
                    >
                      English
                    </Link>
                    <Link
                      to="/fr/"
                      className="block px-4 py-2 text-sm text-gray-700 leading-5 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                      role="menuitem"
                    >
                      French
                    </Link>
                    <Link
                      to="/de/"
                      className="block px-4 py-2 text-sm text-gray-700 leading-5 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                      role="menuitem"
                    >
                      German
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center -mr-2 lg:hidden">
              {/* Mobile menu button */}
              <button
                onClick={() => toggleExpansion(!isExpanded)}
                className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                aria-label="Main menu"
                aria-expanded="false"
              >
                {/* Icon when menu is closed. */}
                {/* Menu open: "hidden", Menu closed: "block" */}
                <svg
                  className="block w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                {/* Icon when menu is open. */}
                {/* Menu open: "block", Menu closed: "hidden" */}
                <svg
                  className="hidden w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/*
          Mobile menu, toggle classNamees based on menu state.

          Menu open: "block", Menu closed: "hidden"
        */}
        <div className={`${isExpanded ? `block` : `hidden`}`}>
          <div className="pt-2 pb-3 text-center">
            {fullLinks.map(link => (
              <Link
                onClick={() => toggleExpansion(!isExpanded)}
                to={link.to}
                key={link.id}
                className="block py-2 pl-3 pr-4 text-base font-medium text-coupa-blue hover:text-coupa-blue-light focus:outline-none transition duration-150 ease-in-out"
              >
                {link.name}
              </Link>
            ))}
            <a
              href="https://twitter.com/rbernshteyn"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ maxWidth: "30px", marginRight: "15px" }}
                className="inline-block social sm:inline-block text-coupa-blue hover:text-coupa-blue-light"
                src={twitterIcon}
                alt=""
              />
            </a>
            <a
              href="https://www.linkedin.com/in/rbernshteyn"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ maxWidth: "30px" }}
                className="inline-block social sm:inline-block text-coupa-blue hover:text-coupa-blue-light"
                src={linkedinIcon}
                alt=""
              />
            </a>
          </div>
        </div>
      </nav>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  links: PropTypes.array
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
